import * as React from "react";
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import "../../css/intro.css";
import Hero from '../pages/hero02';
import "animate.css/animate.compat.css";
import {navigateTo} from "../../helpers/router";
import {t} from "../../i18n";
import { StaticImage } from 'gatsby-plugin-image';
import LandingSection01 from "../parts/LandingSection01";
import LandingBackgroundSection02 from "../backgrounds/landings/Section02";
import LandingBackgroundSection03 from "../backgrounds/landings/Section03";
import LandingBackgroundSection04 from "../backgrounds/landings/Section04";
import LandingBackgroundSection05 from "../backgrounds/landings/Section05";
import IntroListItem from "../utils/IntroListItem";
import NewFooter from './newfooter';
import SEO from "../seo";
export default class Landing02 extends React.Component {
  render() {
    return (
      <Segment basic className="overall-segment">
        <SEO title="Personalizowane suplementy na włosy i paznokcie"
          description="Suplementy na włosy i paznokcie Pure4me zawierają biotynę, skrzyp, pokrzywę i dobre witaminy na włosy. Dobierane są do Twojego wzrostu i wagi."
        />
        <Hero/>
        <LandingSection01>
          <Header as={"h2"} id='intro-h2'>Coś więcej niż <br />zwykłe tabletki<br /> <b>na włosy i paznokcie</b></Header>
          <p id={'section-01-01-lead'}>Marzą Ci się gęste, zdrowe i lśniące włosy? Chcesz żeby rosły szybciej i było ich więcej? Chcesz zapomnieć o takich problemach, jak rozdwojone końcówki i łamliwość? A do tego mieć piękne, mocne paznokcie?</p>
          <p>Oczywiście możesz zacząć przyjmować standardowe tabletki na włosy z biotyną, a do tego dobre witaminy na włosy i skórę, dodatkowe suplementy na wypadanie włosów z ekstraktem z roślin i jeszcze suplementy na paznokcie, ale czy nie lepiej zastąpić je wszystkie jedną, sprawdzoną saszetką, która zawiera dokładnie takie proporcje składników, jakie potrzebuje Twoje ciało?</p>
        </LandingSection01>

        <LandingBackgroundSection02>
          <Segment basic id="section-01-02">
            <Container id="section-01-02-container">
              <Grid stackable relaxed='very'>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <Header as={"h2"} id='intro-h3'>Dobrane specjalnie <b>dla Ciebie</b> suplementy <b>na włosy i paznokcie Pure4me</b></Header>
                    <p>Suplementy na włosy i paznokcie Pure4me to unikalna kompozycja, która dobrana jest indywidualnie pod Twoją wagę i wzrost, a także pod Twój tryb życia. W swoim produkcie znajdziesz zatem wszystkie zioła i witaminy na włosy i skórę, które będą zalecaną dzienną porcją do spożycia właśnie dla Ciebie, a nie uogólnioną porcją dla wszystkich, jaką zawierają dostępne w aptekach tabletki na włosy i paznokcie.</p>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src="../../img/landings/02/section_3.png" alt="Suplementy" placeholder="none"/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection02>

        <Segment basic style={{padding: "42px 0"}}>
          <Container>
            <Grid stackable relaxed='very'>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src="../../img/landings/02/section_4.png" alt="Suplementy" placeholder="none"/>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <Header as={"h2"} id='intro-h3'>Dobre <b>witaminy na włosy i paznokcie</b> + skuteczne <b>ekstrakty z roślin.</b> Tego właśnie potrzebujesz!</Header>
                  <p>Gdy raz spróbujesz, to odłożysz na bok standardowe tabletki na wypadanie włosów i inne specyfiki. 
                    Najlepsze suplementy na włosy i paznokcie to takie suplementy diety, którą łączą w sobie naturalne wyciągi 
                    ze znanego od wieków skrzypu polnego i pokrzywy z tym, co wypracowała nowoczesna farmakologia i wielokrotnie udowodniła medycyna. 
                    W laboratoriach Pure4me, która jest marką posiadającego 25 lat doświadczenia producenta farmaceutycznego Natur Produkt Zdrovit, doskonale wiemy, czym są dobre witaminy na włosy i paznokcie, a technologia pozyskiwania ekstraktów z roślin używanych w naszych suplementach przechodzi najbardziej rygorystyczne testy bezpieczeństwa.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <LandingBackgroundSection03>
          <Segment basic id="section-01-03">
            <Container id="section-01-03-container">
              <Grid stackable relaxed={"very"} centered>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/02/ico_1.png' alt="Biotyna" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Biotyna, czyli królowa witaminy <b>na włosy i paznokcie</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Biotyna nie bez powodu nazywana jest witaminą piękności, a żadne suplementy na wypadanie włosów nie mogą się bez niej obyć. Pobudza bowiem porost włosów. Wszystko to za sprawą zawartej w niej siarki, która stymuluje proces produkcji zawartej we włosach keratyny. Dzięki biotynie łodygi włosów stają się silniejsze i bardziej odporne na łamanie i rozdwajanie. Do tego wzmacnia paznokcie i korzystnie wpływa na ich wygląd.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/02/ico_2.png' alt="Ekstrakt ze skrzypu polnego" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Ekstrakt ze skrzypu polnego - <b>najlepszy dar natury</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Skrzyp polny jest znaną od stuleci rośliną wykorzystywaną dla utrzymania pięknych i bujnych włosów oraz mocnych paznokci. Z uwagi na to, że jest bogaty we flawonoidy, kwasy organiczne, witaminy i minerały, to doskonale uzupełnia poziom elementów, wzmacniając włosy od środka i zapewniając paznokciom twardą, niełamliwą płytkę. Suplementy na włosy i paznokcie ze skrzypu to najlepszy dar natury.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                        <StaticImage src='../../img/landings/02/ico_3.png' alt="Ekstrakt z pokrzywy" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Ekstrakt z pokrzywy - doskonały suplement na <b>porost włosów</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Naturalny wyciąg z pokrzywy działa jak najlepszy suplement na porost włosów. Daje efekty, które widać gołym okiem. Pokrzywa stanowi bowiem wyjątkowo bogatą w ważne składniki roślinę - zawiera w sobie moc witamin i minerałów. Poza stymulacją wzrostu włosów i ich wzmocnieniem, pokrzywa regeneruje skórę głowy.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  </Grid.Row>
                  <Grid.Row>
                    
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/02/ico_4.png' alt="Witamina C" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Witamina C <b>dla najlepszej ochrony cebulek włosowych i cery</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Witamina C chroni organizm w tym m.in. mieszki włosowe przed szkodliwym oddziaływaniem wolnych rodników. Jako, że jest niezwykle silnym przeciwutleniaczem to chroni także Twoją skórę przed stresem oksydacyjnym. Bezpośrednio uczestniczy w syntezie kolagenu, czyli składnika skóry. Wspomaga więc jej nawilżenie, jędrność i spowalnianie procesów starzenia.</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection03>

        <LandingBackgroundSection04>
          <Segment basic id="section-01-04">
            <Container id="section-01-04-container">
              <Grid stackable relaxed={'very'}>
                <Grid.Row>
                  <Grid.Column width={8}>
                  <Header as={'h2'} id='intro-h3'><b>Pure4me</b><br /> jeden dobry suplement <b>na włosy i paznokcie</b> zamiast miliona specyfików</Header>
                  <p>Każda saszetka Pure4me zawiera suplement na włosy i paznokcie, który jest Twoją dzienną porcja blasku, energii i zdrowia. Jego skład
                      kompleksowo podchodzi do problemu wypadania włosów, ich matowości i łamliwości, a także wspomaga uzyskanie mocnych, zdrowo
                      wyglądających paznokci. W składzie nie ma żadnych zbędnych wypełniaczy ani konserwantów - tylko czysta jakość, skuteczność
                      i bezpieczeństwo</p>
                    <p id={'section-01-01-lead'}>Pomyśl, dwie saszetki dziennie zastąpią Ci</p>
                    <div>
                      <IntroListItem>tabletki <b>na włosy z biotyną</b></IntroListItem>
                      <IntroListItem>tabletki <b>na wypadanie włosów</b></IntroListItem>
                      <IntroListItem>suplement <b>na porost włosów</b></IntroListItem>
                      <IntroListItem>witaminy <b>na włosy i paznokcie</b></IntroListItem>
                      <IntroListItem><b>skrzyp i pokrzywę</b></IntroListItem>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src='../../img/landings/02/section_6.png' alt='Pozytywy suplementacji' placeholder='none' />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection04>

        <LandingBackgroundSection05>
          <Segment basic id="section-01-05">
            <Container id="section-01-05-container">
              <Grid stackable relaxed={'very'}>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src='../../img/landings/section_7.png' alt='Suplementy' placeholder='none'/>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={"h2"} id='intro-h2'>Zamów suplementy na włosy i paznokcie <b>Pure4me</b></Header>
                  <p>Suplementy na włosy i suplementy na paznokcie Pure4me to zestaw saszetek na <b>30 dni</b>, które możesz nabyć bezpośrednio przez naszą stronę internetową. Jedyne co musisz zrobić to <b>wypełnić ankietę</b>, która została przygotowana przez nasz zespół naukowy. Dzięki ankiecie składniki zostaną dobrane <b>indywidualnie do Twoich potrzeb</b>. Po udzieleniu odpowiedzi na kilka pytań, otrzymasz od nas propozycję suplementu, który możesz następnie zamówić.</p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-check"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection05>

        <span id="footer">
          <NewFooter/>
        </span>
      </Segment>
    );
  }
}